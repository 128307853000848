<template>
  <div class="container" v-if="pagelist.length > 0">
    <img v-if="isios" :src="currenttask.url" />
    <iframe :src="currenttask.url" frameborder="0" ref="iframe" v-else></iframe>
  </div>
</template>

<script>
import { indexforce, doviewforce } from "@/api/task";
export default {
  name: "forceworker",
  data() {
    return {
      currenttask: {},
      pagelist: [],
      lastpageid: 0,
      isios: false,
      user: "",
    };
  },
  created() {
    var user_agent = navigator.userAgent.toLowerCase();
    this.isios = user_agent.match(/iphone|ipod|ipad/gi);
    let lastpageid = localStorage.getItem("lastpageid");
    if (lastpageid == null) {
      lastpageid = 0;
    } else {
      this.lastpageid = lastpageid;
    }
    this.user = this.$route.query.user;

    indexforce().then((res) => {
      if (res.code == 1) {
        let pages = res.data.pagelist;
        let index = pages.findIndex((v) => v.id == this.lastpageid);
        if (index >= 0) {
          this.pagelist = pages.slice(index + 1);
        } else {
          this.pagelist = pages;
        }
        this.init();
      }
    });
  },
  methods: {
    init() {
      this.dotask();
      setInterval(() => {
        this.lastpageid = this.currenttask.id;
        localStorage.setItem("lastpageid", this.lastpageid);
        this.dotask();
      }, 20 * 1000);
    },
    dotask() {
      doviewforce({ user: this.user, id: this.currenttask.id });
      this.currenttask = this.pagelist.shift();
    },
  },
};
</script>

<style>
.container {
  width: 100vw;
  height: 100vh;
}
iframe {
  width: 100vw;
  height: 100vh;
}
</style>
